.profile_nickname {
    font-family: "Century Gothic", sans-serif;
    font-weight: normal;
    /* text-align: center; */
}

.profile_text{
    /* font-family: "Century Gothic", sans-serif; */
    font-weight: normal;
    /* text-align: center; */
}