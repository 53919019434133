.core_title {
  font-family: "Century Gothic", sans-serif;
  font-weight: normal;
  text-align: center;
}
.core_signUp {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.textfield_large {
  margin: 10px 0;
  width: 100%;
}

.core_text {
  color: rgb(6, 153, 211);
  text-align: center;
  cursor: pointer;
}
.core_header {
  position: sticky;
  top: 0;
  background-color: white;
  padding: 20px;
  border-bottom: 1px solid lightgray;
  object-fit: contain;
  display: flex;
  z-index: 1;
  justify-content: space-between;
}
.core_btnModal {
  background-color: transparent;
  color: gray;
  padding-top: 3px;
  font-size: 32px;
  border: none;
  outline: none;
  cursor: pointer;
}

.core_logout {
  display: flex;
  justify-content: flex-end;
}
.core_posts {
  padding: 20px;
}
.core_progress {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}