.post {
  background-color: white;
  max-width: 100%;
  border: 1px solid lightgray;
  margin-bottom: 10px;
}
.post_header {
  display: flex;
  align-items: center;
  padding: 15px;
}
.post_avatar {
  margin-right: 10px;
  z-index: 0;
}
.post_image {
  width: 100%;
  height: 280px;
  object-fit: contain;
}
.post_text {
  font-weight: normal;
  padding: 20px;
  word-break: break-all;
  white-space: pre-wrap;
}

.post_checkBox {
  z-index: 0;
}
.post_avatarGroup {
  z-index: 0 !important;
}
.post_comments {
  padding: 20px;
}
.post_comment {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  word-break: break-all;
}
.post_commentBox {
  display: flex;
  margin-top: 10px;
}
.post_input {
  flex: 1;
  padding: 10px;
  border: none;
  border-top: 1px solid lightgray;
}
.post_button {
  flex: 0;
  border: none;
  color: #4682b4;
  background-color: transparent;
  border-top: 1px solid lightgray;
  cursor: pointer;
}
.post_strong {
  margin-right: 5px;
}

.customLink {
  text-decoration: none; /* 下線を非表示にする */
  color: inherit; /* 親要素から色を継承し、色を変更しない */
}
